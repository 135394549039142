import React from "react";
import Form from "./Form"

class Contact extends React.Component {
    render(){
        return(
            <div>
                <h1>Contact: </h1>
                <h2>Have a question or want to work together?</h2>
                <Form></Form>

            </div>

        )
    }
}
export default Contact;